import api from "./api";
import TokenService from "./TokenService";

class AuthService {

    login(user) {
        return api
            .post("/auth/signin", {
                email: user.email,
                password: user.password
            })
            .then((response) => {
                if (response.data.accessToken) {
                    TokenService.setUser(response.data);
                }
                return response.data;
            });
    }

    reset(user) {
        return api
            .post("/auth/reset", {
                email: user.email
            })
            .then((response) => {
                return response.data;
            });
    }

    register(user) {
        return api.post("/auth/signup", {
            userType: user.userType,
            email: user.email,
            password: user.password
        });
    }

    changePassword(user) {
        return api.post("/auth/changepassword", {
            resetToken: user.resetToken,
            password: user.password
        });
    }

    logout() {
        TokenService.removeUser();
    }

}

export default new AuthService();