import api from "./api";
import model from "../models/user"

class UserService {

    async getUserByEmail(email) {
        try {
            const { data } = await api.get(`/users/email/${encodeURIComponent(email)}`)
            return data.result[0]
        } catch(error) {
            console.log(error)
            return error
        }
    }
}


export default new UserService();