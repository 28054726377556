import PaymentService from '../services/PaymentService';
import store from './index.js';
export const dash = {
  namespaced: true,
    actions: {
        refresh({ commit }) {
            return PaymentService.getPayments(store.state.auth.user.email, false).then(
                response => {
                    return Promise.resolve(response)
                },
                error => {
                    return Promise.reject(error)
                }
            )

        },
        download({ commit }) {
            return PaymentService.getPayments(store.state.auth.user.email, true).then(
                response => {
                    return Promise.resolve(response)
                },
                error => {
                    return Promise.reject(error)
                }
            )
        },
    }
};