import api from "./api";

class PaymentService {

    //a route to replace the 2 below
    async getPayments(email, doExport) {
        try {
            let route = `/payments/data/email/${email}/export/${doExport}`

            if(doExport){
                const { data } = await api.get(route, {responseType: 'blob'})

                var link = window.document.createElement('a');
                link.href = window.URL.createObjectURL(new Blob([data], {type: "octet/stream"}));
                link.download = link.href.split('/').pop() + '.csv';
                console.log(link.download)
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } else {
                const { data } = await api.get(route)
                return data
            }
            
            
        } catch(error) {
            console.log(error)
            return error
        }
    }

    async getAgentPayments() {
        try {
            let route = "/payments/agent"

            const { data } = await api.get(route)
            return data
        } catch(error) {
            return error
        }
            
    }
    async getMerchantPayments() {
        try {
            let route = "/payments/merchant"

            const { data } = await api.get(route)
            return data
        } catch(error) {
            return error
        }
            
    }
}

export default new PaymentService();