import axios from "axios";
import Configuration from './Configuration'

const instance = axios.create({
  baseURL: Configuration.value('apiURL'),
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;